<template>
  <div class="main">
    <div class="swiper">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(it, ind) in swiperData" :key="ind">
          <div class="swiper-box absolute ani" :class="isOver ? 'tAsBox' : ''"
            style="position: absolute; left: 358px; display: none">
            <div class="swiper-masterName" v-if="it.masterName">
              {{ it.masterName }}
            </div>
            <div class="swiper-slaveName" v-if="it.slaveName">
              {{ it.slaveName }}
            </div>
            <div class="swiper-btn-one" @click="openNewTabs(it.toUrl)" v-if="it.toUrl">
              了解更多<i class="el-icon-right"></i>
            </div>
            <!-- <div
              class="absolute swiper-btn-two"
              @click="openNewTabs(it.url)"
              v-if="it.url"
            >
              了解更多<i class="el-icon-right"></i>
            </div> -->
          </div>
          <img :src="it.url" alt="" width="100%" height="100%" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="swiper-page">
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="mid-line"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </div>

    <div class="info">
      <div class="w12 spb">
        <div class="box" @mouseover="mouseIn1()" @mouseleave="mouseLeave1()">
          <!-- <img src="../assets/img/main/info1.png" class="img1" alt="" /> -->
          <div class="one" v-if="one">实景看库</div>
          <div class="box-c" v-else>
            多维度预览，真实体验观看，全面展现虚实结合的仓库全景！
          </div>
        </div>
        <div class="box" @mouseover="mouseIn2()" @mouseleave="mouseLeave2()">
          <!-- <img src="../assets/img/main/info2.png" class="img2" alt="" /> -->
          <div class="two" v-if="two">真实仓源</div>
          <div class="box-c" v-else>
            仓源认证有保障，现场实勘，人工信息采集有保障！
          </div>
        </div>
        <div class="box" @mouseover="mouseIn3()" @mouseleave="mouseLeave3()">
          <!-- <img src="../assets/img/main/info3.png" class="img3" alt="" /> -->
          <div class="three" v-if="three">优质推荐</div>
          <div class="box-c" v-else>
            货兑宝平台优质推荐，安全、省心、放心的精选仓库！
          </div>
        </div>
        <div class="box" @mouseover="mouseIn4()" @mouseleave="mouseLeave4()">
          <!-- <img src="../assets/img/main/info4.png" class="img4" alt="" /> -->
          <div class="four" v-if="four">覆盖全国</div>
          <div class="box-c" v-else>
            聚合全国海量仓库资源，满足多样化仓储需求，多种仓库任您选择！
          </div>
        </div>
      </div>
    </div>

    <div style="width: 100%;">
      <router-view></router-view>
    </div>




    <div class="future">
      <div class="w12 flex spb">
        <div class="l">
          <h1>数字科技开拓未来</h1>
          <h6>共建更公平 更高效 更规范 的现代流通体系</h6>
        </div>
        <div class="r">
          <div class="flex">
            <div class="r-l"></div>
            <div class="r-r">
              <p class="p1">联系咨询</p>
              <p class="p2">400-673-7868 转 3</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about w12 flex">
      <div class="l"></div>
      <div class="r">
        <div class="r-t"></div>
        <div class="r-e"></div>
      </div>
      <div class="ab">
        <div class="ab-t">京科仓链介绍</div>
        <p class="p1">
          “京科仓链”是中储京科旗下，基于互联网技术为国内仓储行业打造的数据化产品，于2022年5月正式上线，旨在为仓储企业提供线上仓库展示服务，为货主提供在线选仓平台。
        </p>
      </div>
    </div>

    <div class="par">
      <div class="title"></div>
      <div class="w12 flexws">
        <div class="box flex" v-for="(item, index) in imgList" :key="index">
          <img :src="item" alt="" class="logo-img" />
          <!-- <div class="box-title">{{ "wosititle" + it }}</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let id = 0;
import { banner } from "@/api/banner";
import house from "@/api/housedetail";
import mainpage from "@/api/mainpage";
import Card from "../components/Card/index.vue";
// import { EffectCube, Pagination } from "swiper";

export default {
  components: {
    Card,
  },
  data() {
    let that = this;
    return {
      // modules: [EffectCube, Pagination],
      preActiveNum: 0,
      imgList: [
        require("../assets/logo/logo5.jpg"),
        require("../assets/logo/logo3.jpg"),
        require("../assets/lingxi/logo.png"),
        require("../assets/logo/logo4.jpg"),
        require("../assets/logo/logo2.jpg"),
        require("../assets/logo/logo6.jpg"),
        require("../assets/logo/logo7.jpg"),
        require("../assets/logo/logo8.jpg"),
        require("../assets/logo/logo9.jpg"),
      ],
      activeName: 'first',

      index: 0,
      selectedOptions: [],
      navInputProps: {
        lazy: true,
        value: "id",
        label: "name",
        lazyLoad: (node, resolve) => {
          const { level } = node;
          house
            .mapList({
              showAll: 0,
              pid: node.level === 0 ? null : node.data.id,
            })
            .then((res) => {
              res.data.forEach((it) => {
                it.leaf = level >= 2;
              });
              resolve(res.data);
            });

          // setTimeout(() => {
          //   const nodes = Array.from({ length: level + 1 }).map((item) => ({
          //     value: ++id,
          //     label: `选项${id}`,
          //     leaf: level >= 2,
          //   }));
          //   // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          //   resolve(nodes);
          // }, 1000);
        },
      },
      swiperOption: {
        effect: "cube",
        cubeEffect: {
          slideShadows: false,
          shadow: false,
        },
        grabCursor: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,
        // mousewheel: true,
        // loop: true,
        speed: 2000,
        autoplay: {
          delay: 6 * 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // coverflowEffect: {
        //   rotate: 0, // 旋转的角度
        //   stretch: 100, // 拉伸   图片间左右的间距和密集度
        //   depth: 150, // 深度   切换图片间上下的间距和密集度
        //   modifier: 3, // 修正值 该值越大前面的效果越明显
        //   slideShadows: false, // 页面阴影效果
        // },
        on: {
          init: function (swiper) {
            that.isOver = true;
          },
          transitionStart: function () {
            that.isOver = false;
          },
          transitionEnd: function () {
            that.isOver = true;
          },
          // slideChangeTransitionStart() {
          //   alert("111");
          //   // setTimeout(() => {
          //   that.isOver = false;
          //   // }, 1000);
          // },
          // init() {
          //   that.isOver = true;
          //   // setTimeout(() => {
          //   //   that.isOver = false;
          //   // }, 3000);
          // },

          // slideChangeTransitionEnd() {
          //   // alert("222");
          //   // setTimeout(() => {
          //   that.isOver = true;
          //   // }, 2000);
          // },
        },
      },
      isOver: false,
      swiperOptionVR: {
        slidesPerView: 3,
        slidesPerGroup: 6,
        // loop: true,
        // loopFillGroupWithBlank: true,
        spaceBetween: 60,
        slidesPerColumnFill: "row",
        slidesPerColumn: 2,

        paginationClickable: true,

        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
      },
      swiperData: [],
      form: {
        province: "",
        provinceId: "",
        provinceOp: [],
        city: "",
        cityId: "",
        countyId: "",
        cityOp: [],
      },

      searchData: "",
      showActive: false,
      newData: [],
      digital: [],
      activeName: "0",
      one: true,
      two: true,
      three: true,
      four: true,
      wareHouseListVr: {},
      wareHouseList: [],
    };
  },
  watch: {
    activeName(cur, pre) {
      this.preActiveNum = pre;
    },
  },
  created() {
    localStorage.setItem("menu", 1)
  },
  mounted() {
    this.get();
    // house.shareInfo().then((res) => {
    //   // console.log(0,res);
    // });
    // house
    //   .warehouseDetailById("57606a41cd934e9da378d9724dd50c66")
    //   .then((res) => {
    //     // console.log(111,res);
    //   });
    // house
    //   .warehouseRecommendList("57606a41cd934e9da378d9724dd50c66")
    //   .then((res) => {
    //     // console.log(222,res);
    // });
    // house.commonCategoryList().then((res) => {
    //   console.log(333, res);
    // });
    house.mapList({ showAll: 1, pid: null }).then((res) => {
      this.form.provinceOp = res.data;
      this.cascaderArry = res.data;
      // console.log(444,res);
    });
    // house
    //   .warehouseBannerList("57606a41cd934e9da378d9724dd50c66")
    //   .then((res) => {
    //     // console.log(555,res);
    //   });
    // house
    //   .warehousePageInfo({
    //     pageNum: 1,
    //     pageSize: 10,
    //     warehouseName: "",
    //     province: "",
    //     city: "",
    //     county: "",
    //     categoryIds: [],
    //   })
    //   .then((res) => {
    //     // console.log(666,res);
    //   });
    // house
    //   .mapAreaAndWarehouseInfos({
    //     warehouseName: "",
    //     province: "",
    //     city: "",
    //     county: "",
    //     categoryIds: [],
    //     areaIds: ["120000"],
    //   })
    //   .then((res) => {
    //     // console.log(777,res);
    //   });
    this.wareHouseListVr = {};
    mainpage.homeRecommendlist().then((res) => {
      // console.log(888, res);
      // this.wareHouseList = res.data

      // console.log("res.data", res);
      if (res.data == null) {
        return
      }
      const data = res.data.reverse();
      this.wareHouseList = data
      // this.wareHouseListVr = data.filter((item) => item.code == "vr")[0];
      // this.wareHouseList = data.filter((item) => item.code !== "vr");
      // console.log(this.wareHouseList);

      // res.data.forEach((it) => {
      //   // console.log(it);
      //   if (it.code == "vr") {
      //     // this.wareHouseListVr = it;
      //   } else {
      //     this.wareHouseList.push(it);
      //   }
      // });
    });
    // house.mapAreaAndWarehouseInfos().then((res)=>{
    //   console.log(11,res);
    // })
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    get() {
      banner().then((res) => {
        this.swiperData = res.data;
        // console.log(res);
      });
    },

    openNewTabs(url) {
      window.open(url);
    },

    goToMap(val) {
      // console.log("cityw", this.selectedOptions);

      if (val == "more") {
        return this.$router.push({
          path: "/map",
        });
      }

      this.$router.push({
        path: "/map",
        query: {
          name: this.searchData,
          provinceId: this.selectedOptions[0],
          cityId: this.selectedOptions[1],
          countyId: this.selectedOptions[2],
        },
      });
    },
    goRecruitment(val) {
      this.$router.push({
        path: "/recruitment",
        query: {
        },
      });
    },
    showHouses(it) {
      this.showActive = it === 1 ? true : false;
      if (it) {
        // console.log("housedetail", house);
      } else {
      }
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    mouseIn1(t) {
      this.one = false;
    },
    mouseIn2(t) {
      this.two = false;
    },
    mouseIn3(t) {
      this.three = false;
    },
    mouseIn4(t) {
      this.four = false;
    },
    mouseLeave1(t) {
      this.one = true;
    },
    mouseLeave2(t) {
      this.two = true;
    },
    mouseLeave3(t) {
      this.three = true;
    },
    mouseLeave4(t) {
      this.four = true;
    },
  },
};
</script>
<style scoped>
/deep/ .v-modal {
  z-index: 1000 !important;
}

.listData {
  display: flex;
  flex-wrap: wrap;
}

.every_list {
  width: 45%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 20px;
  margin-bottom: 30px;
}

.every_list2 {
  width: 45%;
  margin-bottom: 60px;
  padding: 20px;
  margin-bottom: 30px;
}

.list_title {
  width: 80%;
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.list_content {
  display: flex;
  flex-wrap: wrap;

}

.every_content {
  /* background-color: #999999; */
  width: 90%;
}

.everyContent {
  width: 45%;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 29px;
}

.everyContent1 {
  width: 80%;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 29px;
  margin-bottom: 20px;
}

.last_bottom {
  width: 80%;
  display: flex;
  justify-content: space-between;


}

.gongsi,
.shijian {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #CCCCCC;
}

.list_ico img {
  width: 24px;
  height: 24px;
}

img {
  position: relative;
  z-index: 0;
}

.el-o {
  font-weight: 500;
}

.firstActive {
  animation: firstActive 0.5s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  /*Safari and Chrome*/
}

@keyframes firstActive {
  0% {
    transform: translateX(-500px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.secondActive {
  animation: secondActive 0.5s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  /*Safari and Chrome*/
}

@keyframes secondActive {
  0% {
    transform: translateX(500px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.tAsBox {
  animation: fadenum 2s;
  display: block !important;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  /*Safari and Chrome*/
}

@keyframes fadenum {
  0% {
    opacity: 0;
    /* transform: translateY(70px); */
    top: 300px;
  }

  100% {
    opacity: 1;
    top: 250px;
  }
}

.w11 {
  padding: 50px 0 0px 0;
}

.slide-swiper {
  /* width: 357px !important; */
  height: 257px;
  margin-top: 60px;
  /* margin-left: 64px; */
}

.slide-swiper:first-child {
  margin-left: 0;
}

.slide-swiper .card {
  width: 357px !important;
  height: 257px !important;
}

/deep/.slide-swiper .card .card-box {
  width: 357px !important;
  height: 257px !important;
}

.vr-swiper .swiper-container {
  padding-bottom: 76px;
}

.flexws-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexws-box .warehouse-card {
  min-height: 476px;
}
</style>
